.rightbarContent {
  padding: 25px;
  width: 80%;
}

.trends {
  background-color: #272626ed;
  border-radius: 25px;
  min-height: calc(100vh - 200px);
  max-width:290px;
  margin-top: 40px;
  padding: 20px;
  font-weight: bold;
  font-size: 18px;
  overflow: hidden;
  text-align: center;
}

.trend{
    display:flex;
    justify-content: start;
    align-items: center;
    gap:20px;
    margin-top: 15px;
    padding: 10px;
}

.trend:hover{
    cursor: pointer;
    background-color: #2c353e;
}
.trendImg{
    width: 100px;
    border-radius: 3px;
}
.trendText {
    font-size: 15px;
    font-weight: normal;
}
.more {
    font-size: 15px;
    font-weight: normal;
    padding: 15px;
    color:#1DA1F2;
}
.category{
    background-color: rgb(54, 54, 54);
    border-radius: 14px;
    margin-top: 20px;
    width: 230px;
    height: 40px;
    text-align: center;
    padding-top: 15px;
    border: 2px solid black;
    font-size: 16px;

}
.hovera{
    background-color: rgb(9, 163, 253);
    border-radius: 14px;
    margin-top: 20px;
    width: 230px;
    height: 40px;
    text-align: center;
    padding-top: 15px;
    border: 2px solid black;
    font-size: 16px;
}
.category:hover{
    cursor: pointer;
    background-color: rgb(24, 23, 23);

}
