.page {
  display: flex;
  justify-content: space-between;
  background-color: rgba(47,49,54,255);
  width: 100%;
  height: 100%;
  overflow-x: none;
  
}


.sideBar {
  position: sticky;
  top: 0px;
  width: calc((100vw - 800px)*0.45);
  color: white;
  height: 100%;
  padding-left: 5.859375vw;
  background-color: rgba(54,57,63,255);
  
}
.rightBar {
  position: sticky;
  top: 0px;
  width: calc((100vw - 800px)*0.6);
  color: white;
  height: 100%;
  background-color: rgba(54,57,63,255);
  
}
/* width */
::-webkit-scrollbar {
  width: 12px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f5f5f5; 
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.mainWindow {
  width: 800px;
}
/* Text Field */
.sc-fWIMVQ {
  background-color: transparent !important;
  border-right: 0px !important;
  border-top: 0px !important;
  border-left: 0px !important;
  border-radius: 0px !important;
  border-bottom: 1px solid rgb(63, 63, 63) !important;
  color: white !important;
  height: 90px !important;
}
/* Text Area */
.sc-jWEIYm {
  color: white !important;
  fill: white !important;
  font-size: 20px !important;
  height: 10px !important;
}
.rdMZu {
    min-height: 90px !important;
}
a:-webkit-any-link {
  cursor: pointer;
  text-decoration: none !important;
  height: 0px !important;
}
.logout{
  display: flex;
  justify-content: end;
  margin-right: 100px;
  font-weight: bold;
}
.logout:hover {
  color:gray;
  font-weight: bold;
  cursor: pointer;
}
.loginPage {
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
.logindiv{
  background-color: rgba(20,29,60,255);
  height: 65vh;
  width: 57vh;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  border: 1px solid white; 
}
.logoimage{
  width: 13.850415512465373vh;
}
@media (max-width:415px){
  .logindiv{
    background-color: rgba(20,29,60,255);
    height: 65vh;
    width: 50vh;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    border: 1px solid white; 
  }
}
@media all and (max-width:414px){
  .logindiv{
    background-color: transparent;
    height: 65vh;
    width: 50vh;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    border: 1px solid transparent; 
  }
}
  @media all and (min-width:1500px){
    .sideBar {
      position: sticky;
      top: 0px;
      width: calc((100vw - 800px)*0.35);
      color: white;
      height: 100vh;
      padding-left: 5.859375vw;
      background-color: rgba(54,57,63,255); 
    }
    .rightBar {
      position: sticky;
      top: 0px;
      width: calc((100vw - 800px)*0.55);
      color: white;
      height: 100vh;
      background-color: rgba(54,57,63,255);
    }
}
@media all and (max-width:1232px){
  .sideBar {
    position: sticky;
    top: 0px;
    width: calc((100% - 800px)*0.6);
    color: white;
    height: 100vh;
    padding-left: 5.859375vw;
    background-color: rgba(54,57,63,255);
  }
  .rightBar {
    position: sticky;
    top: 0px;
    width: calc((100% - 800px)*0.9);
    color: white;
    height: 100vh;
    background-color: rgba(54,57,63,255);
  }
}
@media all and (max-width:1100px){
  .sideBar {
    position: sticky;
    top: 0px;
    width: calc((100% - 800px));
    color: white;
    height: 100vh;
    padding-left: 5.859375vw;
    background-color: rgba(54,57,63,255);
  }
  .rightBar {
    position: sticky;
    top: 0px;
    width: calc((100% - 700px)*1.2);
    color: white;
    height: 100vh;
    background-color: rgba(54,57,63,255);
  }
}
@media all and (max-width:950px){
  .sideBar {
    position: sticky;
    top: 0px;
    width: calc((100% - 800px) * 2);
    color: white;
    height: 100vh;
    padding-left: 5.859375vw;
    background-color: rgba(54,57,63,255);
  }
  .rightBar {
    display: none;
  }
}
@media all and (max-width:889px){
  .sideBar {
    position: sticky;
    top: 0px;
    width: calc((100% - 74.083333333333336vw));
    color: white;
    height: 100vh;
    padding-left: 5.859375vw;
    background-color: rgba(54,57,63,255);
  }
  .rightBar {
    display: none;
  }
}
@media all and (max-width:770px){
  .sideBar {
    position: sticky;
    top: 0px;
    width: 100px;
    color: white;
    height: 100vh;
    padding-left: 5.859375vw;
    background-color: rgba(54,57,63,255);
  }
}
@media all and (max-width:600px){
  .sideBar {
    display: none;
  }
}

